\<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            label="장치명"
            name="deviceName"
            v-model="searchParam.deviceName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            label="장치번호"
            name="equipmentNo"
            v-model="searchParam.equipmentNo">
          </c-text>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 관련공정 -->
          <c-process
            label="LBL0001705"
            multiple="single"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 관리부서 -->
          <c-dept
            type="search"
            label="LBL0001721"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <!-- 장치 및 설비 목록 -->
    <c-table
      ref="deviceMachine"
      title="LBL0010387"
      tableId="deviceMachine"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :merge="grid.merge"
      rowKey="psiDeviceEquipmentId"
      :columnSetting="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn
            v-if="editable"
            label="엑셀업로드"
            icon="upload"
            @btnClicked="excelUploadData" />
          <!-- 등록 -->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="equipmentPopup" />
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'device-machine',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'equipmentNo' },
          { index: 1, colName: 'equipmentNo' },
          { index: 2, colName: 'equipmentNo' },
          { index: 3, colName: 'equipmentNo' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:80px',
            fix: true,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
            fix: true,
          },
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            // 장치번호
            label: 'LBL0010388',
            align: 'center',
            style: 'width:120px',
            sortable: true,
            fix: true,
          },
          {
            name: 'deviceName',
            field: 'deviceName',
            // 장치명
            label: 'LBL0010389',
            align: 'left',
            style: 'width:200px',
            type: 'link',
            sortable: true,
            fix: true,
          },
          {
            name: 'devicePartName',
            field: 'devicePartName',
            // 장치구분
            label: 'LBL0010390',
            align: 'center',
            style: 'width:80px',
            sortable: true,
            fix: true,
          },
          {
            name: 'inMaterials',
            field: 'inMaterials',
            // 내용물
            label: 'LBL0010391',
            align: 'center',
            style: 'width:120px',
            type: 'html',
            sortable: true,
            fix: true,
          },
          {
            name: 'volume',
            field: 'volume',
            // 용량
            label: 'LBL0010392',
            align: 'center',
            style: 'width:150px',
            type: 'html',
            sortable: true,
            fix: true,
          },
          {
            // 압력(MPa)
            label: 'LBL0010393',
            align: 'center',
            child: [
            {
              name: 'pressOperation',
              field: 'pressOperation',
              // 운전
              label: 'LBL0010394',
              align: 'center',
              style: 'width:60px',
              sortable: true,
            },
            {
              name: 'pressDesign',
              field: 'pressDesign',
              // 설계
              label: 'LBL0010395',
              align: 'center',
              style: 'width:60px',
              sortable: true,
            },
            ]
          },
          {
            // 온도(℃)
            label: 'LBL0010396',
            align: 'center',
            child: [
            {
              name: 'tempOperation',
              field: 'tempOperation',
              // 운전
              label: 'LBL0010394',
              align: 'center',
              style: 'width:60px',
              sortable: true,
            },
            {
              name: 'tempDesign',
              field: 'tempDesign',
              // 설계
              label: 'LBL0010395',
              align: 'center',
              style: 'width:60px',
              sortable: true,
            },
            ]
          },
          {
            // 사용재질
            label: 'LBL0010397',
            align: 'center',
            child: [
            {
              name: 'materialMain',
              field: 'materialMain',
              // 본체
              label: 'LBL0010398',
              align: 'center',
              style: 'width:120px',
              sortable: true,
            },
            {
              name: 'materialSubPart',
              field: 'materialSubPart',
              // 부속품
              label: 'LBL0010399',
              align: 'center',
              style: 'width:120px',
              sortable: true,
            },
            {
              name: 'materialGasket',
              field: 'materialGasket',
              // 개스킷
              label: 'LBL0010400',
              align: 'center',
              style: 'width:120px',
              sortable: true,
            },
            ]
          },
          {
            name: 'weldingEfficiency',
            field: 'weldingEfficiency',
            // 용접효율
            label: 'LBL0010401',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'calculateThick',
            field: 'calculateThick',
            // 계산두께(mm)
            label: 'LBL0010402',
            align: 'center',
            sortable: true,
          },
          {
            name: 'corrosionAllowance',
            field: 'corrosionAllowance',
            // 부식여유(mm)
            label: 'LBL0010403',
            align: 'center',
            sortable: true,
          },
          {
            name: 'useThick',
            field: 'useThick',
            // 사용두께(mm)
            label: 'LBL0010404',
            align: 'center',
            sortable: true,
          },
          {
            name: 'afterHeatTreatYn',
            field: 'afterHeatTreatYn',
            // 후열처리여부
            label: 'LBL0010405',
            align: 'center',
            sortable: true,
          },
          {
            name: 'nondestructPercent',
            field: 'nondestructPercent',
            // 비파괴검사율(%)
            label: 'LBL0010406',
            align: 'center',
            sortable: true,
          },
          {
            name: 'applicableLaws',
            field: 'applicableLaws',
            // 적용법령
            label: 'LBL0010407',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
        ],
        data: [],
      },
      searchParam: {
        deviceName: '',
        plantCd: '',
        equipmentNo: '',
        processCd: '',
        deptCd: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.psi.pfi.device.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.equipmentPopup(row);
    },
    equipmentPopup(result) {
      this.popupOptions.target = () => import(`${"./deviceEquipmentDetail.vue"}`);
      this.popupOptions.title = 'LBL0010409'; // 장치 및 설비 상세
      this.popupOptions.param = {
        psiDeviceEquipmentId: result ? result.psiDeviceEquipmentId : '',
      };
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    excelUploadData() {
      this.popupOptions.title = '장치 및 설비 업로드';
      this.popupOptions.target = () => import(`${'./deviceEquipmentExcelUpload.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploadPopup;
    },
    closeExcelUploadPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        this.$_.forEach(s_data, item => {
          // item.plantCd = this.searchParam.plantCd;
          item.SysRevision = 1;
          item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
          item.chgUserId = this.$store.getters.user.userId;  // 수정자 ID
        })

        this.$http.url = transactionConfig.psi.pfi.device.mst.insert.url + '/excel';
        this.$http.type = 'POST';
        this.$http.param = s_data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        });
      }
    },
  }
};
</script>
